import { useSearchParams } from "react-router-dom";

import UsersProvider from "./provider"
import Main from "./main"
import ActiveUsers from "./active-users/active-users";
import Header from "./header";
import UsersFilterSidebar from "./user-filter";

const UsersPage = () => {
	const [params] = useSearchParams();
	const onlyActiveUsers = params.get('activeUsers') === 'true';

	return (
		<UsersProvider>
			<>
				<Header />
				{onlyActiveUsers ? <ActiveUsers /> : <Main />}
				<UsersFilterSidebar />
			</>
		</UsersProvider>
	)
}

export default UsersPage