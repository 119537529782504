import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useToasterContext } from "@src/context/Toaster";
import { AlertType } from "@src/lib/types/toaster";
import { verifyUserAPI } from "@src/api/auth";
import { VerifyUserFormValues } from "@src/lib/types/auth";

export default function AccountVerification() {
  const { triggerOpen } = useToasterContext();
  const [searchParams] = useSearchParams();
  const idValue = searchParams.get('userId');
  const codeValue = searchParams.get('code');

  const { mutate: verifyUser, isSuccess, isError } = useMutation({
    mutationFn: async ({ code, userId }: VerifyUserFormValues) => {
      try {
        await verifyUserAPI({
          userId,
          code,
        });
      } catch (error) {
        triggerOpen({
          type: AlertType.error,
          title: 'Account verification',
          message: 'Failed to verify account'
        })
      }
    }
  })

  useEffect(() => {
    if (idValue && codeValue) {
      setTimeout(() => {
        verifyUser({
          userId: idValue,
          code: codeValue,
        });
      }, 1000 * 3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idValue, codeValue]);

  const renderContent = () => {
    if (isError) {
      return (
        <h1>
          Apologies, we encountered an error while verifying your email address. Please double-check the provided email or contact support for assistance.
        </h1>
      )
    }

    if (isSuccess) {
      return (
        <img
          alt="verification success"
          src="/assets/images/signup/verify-success.png"
          style={{
            margin: '0 auto',
            pointerEvents: 'none'
          }}
        />
      );
    }

    return (
      <h1>Verifying account...</h1>
    );
  }

  return (
    <Box
      flex={1}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#ffffff'
      }}
    >
      {renderContent()}
    </Box>
  )
}