import { Card } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import { useCompanyProfileService } from './hooks';
import LicenseKeyModal from './license-key-modal';
import Profile from './profile';
import UpdateLicenseKeyModal from './update-license-key-modal';
import { Helmet } from 'react-helmet-async';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CompanyProfile() {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const { onPartnerActivateLicense } = useCompanyProfileService();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleClose = () => setOpen(false);
  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);

  const handleActivateLicense = (license: string) => {
    onPartnerActivateLicense.mutate({ licenseKey: license }, {
      onSuccess: () => {
        handleCloseUpdate();
      }
    });
  }

  return (
    <>
      <Helmet>
        <title>Company Profile</title>
      </Helmet>
      <Card sx={{ width: '100%', height: "100%", px: "30px", py: "15px", borderRadius: "25px" }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Company Profile" {...a11yProps(0)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Profile handleActivateLicense={() => setOpen(true)} />
        </CustomTabPanel>
        <LicenseKeyModal
          open={open}
          onClose={handleClose}
          handleOpenUpdate={handleOpenUpdate}
        />
        <UpdateLicenseKeyModal
          open={openUpdate}
          onClose={handleCloseUpdate}
          onActivate={handleActivateLicense}
          isActivating={onPartnerActivateLicense.isPending}
        />
      </Card>
    </>
  );
}