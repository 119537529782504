import React from 'react';
import { MenuItem, Popover } from '@mui/material'

import { useRedeemTransactionsContext } from './provider';

function ActionMenu() {
  const { state, handleCloseMenu, handleOpenUpdateRedeemStatus } = useRedeemTransactionsContext();
  const { menuOpen, anchorEl } = state || {};

  const onViewMoreDetails = () => {
    handleOpenUpdateRedeemStatus();
    handleCloseMenu();
  };

  return (
    <Popover
      open={Boolean(menuOpen)}
      anchorEl={anchorEl}
      onClose={() => handleCloseMenu()}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem onClick={onViewMoreDetails}>
        More details
      </MenuItem>
    </Popover>
  )
}

export default ActionMenu;