import { endOfMonth, startOfMonth } from "date-fns";

export const getCurrentMonthDays = () => {
  const currentDate = new Date();
  const firstDayOfMonth = startOfMonth(currentDate);
  const lastDayOfMonth = endOfMonth(currentDate);

  return {
    fromDate: firstDayOfMonth.toISOString(),
    toDate: lastDayOfMonth.toISOString(),
  };
};
