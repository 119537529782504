import Body from './body';
import PurchasesFilterSidebar from './filter';
import PurchaseProvider from './provider';

const PurchaseRecords = () => {

  return (
    <PurchaseProvider>
      <>
        <Body />
        <PurchasesFilterSidebar />
      </>
    </PurchaseProvider>
  );
}

export default PurchaseRecords;
