import axiosClient from "@src/utils/axios-client";

export const gameCategoriesListAPI = async () => {
    try {
        const result = await axiosClient.get("/game-categories");
        return result.data.data || {};
    } catch (error: any) {
        throw error.response.data.error;
    }
};
