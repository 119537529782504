import { GetRedeemTransactionsAPIParams } from "@src/lib/types/redeem-transactions";
import axiosClient from "@src/utils/axios-client";

export const getRedeemTransactionsAPI = async (params?: GetRedeemTransactionsAPIParams) => {
    try {
        const result = await axiosClient.post("/redeem/get", params);
        return result?.data || {};
    } catch (error: any) {
        throw error.response.data.error;
    }
};

export const exportRedeemTransactionsListAPI = async (params?: GetRedeemTransactionsAPIParams) => {
    try {
        const result = await axiosClient.post("/redeem/get/noPaging", params);
        return result.data;
    } catch (error: any) {
        throw error.response.data.error;
    }
};

// todo - type params
export const updateRedeemTransactionAPI = async (params?: any) => {
    try {
        const result = await axiosClient.post("/redeem/update", params);
        return result.data;
    } catch (error: any) {
        throw error.response.data.error;
    }
};

export const getRedeemTransactionDocumentAPI = async (userId: string | number) => {
    try {
        const result = await axiosClient.get(`/redeem/userDocument/get?userid=${userId}`);
        return result.data;
    } catch (error: any) {
        throw error.response.data.error;
    }
}

export const getRedeemStats = async () => {
    try {
        const result = await axiosClient.get(`/redeem/stats`);
        return result.data;
    } catch (error: any) {
        throw error.response.data.error;
    }
}