import { LoadingButton } from '@mui/lab';
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";

import { Dialog } from "@src/components/dialog";
import { FormInput } from '@src/components/form-input';
import { Select } from "@src/components/select";

import Config from '@src/utils/api/config';
import { UserUpdateBalanceFormType } from "@src/lib/types/users";

import { useAuthService } from '@src/store/hooks';

import { useUsersService } from '../hooks';
import { useUsersContext } from "../provider";

const { isPalmsPlay } = Config;

const COINS = !isPalmsPlay ? [
    { value: 1, label: 'Silver' },
    { value: 2, label: 'GWz Gold' },
    { value: 3, label: 'Gold Bonus' },
] : [
    { value: 1, label: 'Silver' },
    { value: 2, label: 'Palms Gold' },
    { value: 3, label: 'Bonus Gold' },
]

const UserBalance = () => {
    const { currentUser } = useAuthService();
    const { onUpdateUserBalance } = useUsersService();
    const { state, handleCloseUpdateBalance } = useUsersContext();
    const { editBalanceOpen, selectedUser } = state ?? {};

    const { control, getValues } = useForm<UserUpdateBalanceFormType>({
        defaultValues: {
            amount: 0,
            coinTypeID: 1,
        }
    });

    const onSubmit = () => {
        const values = getValues();
        const params = {
            ...values,
            userID: selectedUser?.id,
            authorizedByUserID: currentUser.id,
        }
        onUpdateUserBalance.mutate(params);
    }

    return (
        <Dialog
            isOpen={Boolean(editBalanceOpen)}
            onClose={handleCloseUpdateBalance}
            dialogTitle={"Update Balance"}
            dialogContent={
                <>
                    <Select
                        name="coinTypeID"
                        control={control}
                        options={COINS}
                        labelId="coinTypeID-select"
                        label="Coin Type"
                        size="small"
                    />
                    <FormInput
                        internalLabel
                        name="amount"
                        label="Amount"
                        control={control}
                    />
                </>
            }
            dialogActions={(
                <>
                    <LoadingButton onClick={onSubmit}>
                        Update
                    </LoadingButton>
                    <Button
                        autoFocus
                        onClick={handleCloseUpdateBalance}
                        sx={{ color: 'text.secondary' }}
                    >
                        Close
                    </Button>
                </>
            )}
        />
    )
}

export default UserBalance