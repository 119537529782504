import React from 'react';
import { Box, Button, Typography } from '@mui/material';

import { fileName } from '@src/utils/transform-helper';

import { useRedeemTransactionsContext } from '../provider';

import { useRedeemTransactionsService } from '../hooks';

const boxStyle = {
    display: 'flex',
    flex: 1,
    flexDirection: "column",
    justifyContent: 'flex-start',
    marginTop: 0,
    p: 2,
};

const boxRow = {
    display: 'flex',
    flex: 1,
    flexDirection: "row",
    justifyContent: 'flex-start',
    alignItems: 'center',
    mt: 0,
};

const ProofOfIDs = () => {
    const { state } = useRedeemTransactionsContext();
    const { userDocumentData } = useRedeemTransactionsService();

    const openAttachment = (url: string) => {
        const win: any = window.open(url, '_blank');
        win.focus();
    }

    if (state?.selected?.redeemTypeID && ![1, 2, 3].includes(state?.selected?.redeemTypeID)) return null;

    return (
        <>
            <Typography sx={{ m: 0, pl: 2, pt: 2, fontSize: 14, fontWeight: "600" }}>
                PROOF OF ID ATTACHMENTS
            </Typography>
            <Box sx={boxStyle}>
                {(userDocumentData || []).map((item: any) => {
                    return (
                        <Box key={item.id} sx={boxRow}>
                            <Typography sx={{ fontSize: 14, fontWeight: "500" }}>
                                {fileName(item.filePath)}
                            </Typography>
                            <Button onClick={() => openAttachment(item.filePath)} sx={{ fontSize: 9, ml: 1 }}>
                                OPEN ATTACHMENT
                            </Button>
                        </Box>
                    )
                })}
            </Box>
        </>
    );
}

export default ProofOfIDs;