import React from 'react';
import { Box, Typography } from '@mui/material';

import { FormInput } from '@src/components/form-input';

import { useRedeemTransactionsContext } from '../provider';

const boxStyle = {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 1,
    marginTop: 2,
};

const GiftCardItem = ({ control }: any) => {
    const { state } = useRedeemTransactionsContext();
    const { selected } = state || {};

    if (selected?.redeemTypeID !== 3) return null;

    return (
        <>
            <Typography
                sx={{
                    m: 0,
                    p: 2,
                    fontSize: 14,
                    fontWeight: "600",
                }}
            >
                Gift Card Item
            </Typography>
            <Box sx={boxStyle}>
                <FormInput
                    name="giftCardID"
                    control={control}
                    label="Gift Card ID"
                    disabled
                />
                <FormInput
                    name="giftCardName"
                    control={control}
                    label="Gift Card Name"
                    disabled
                />
            </Box>
        </>
    );
}

export default GiftCardItem;