// @mui
import { Box, ButtonBase, Card, Typography, useTheme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));

type AppProps = {
  color?: string;
  icon?: React.ReactNode | string;
  title: string;
  value?: string | number;
  sx?: any;
  noAmount?: boolean;
  labelProps?: any;
  valueProps?: any;
  disabled?: boolean;
  onClick?: (args?: any) => void;
}

export default function AppWidgetSummary({ title, value, icon, color = '#111111',
  sx, labelProps, valueProps, noAmount, disabled, onClick }: AppProps) {
    const theme: any = useTheme();

  return (
    <Card sx={{
      pl: "20px",
      pr: "20px",
      boxShadow: 0,
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      textAlign: 'center',
      height: {
        xl: "120px",
        lg: "120px",
        md: "110px",
        sm: "85px",
        xs: "85px",
      },
      bgcolor: "white",
      borderRadius: {
        xl: "25px",
        lg: "25px",
        md: "20px",
        sm: "15px",
        xs: "15px",
      },
      ...sx,
    }}
    >
      <ButtonBase disabled={disabled} disableRipple onClick={onClick} sx={{gap: "0.7rem"}}>
      <Box>
        <StyledIcon
          sx={{
            color,
            backgroundImage: () =>
              `linear-gradient(135deg, ${alpha(color, 0)} 0%, ${alpha(
                color,
                0.24
              )} 100%)`,
            width: {
              xl: theme.spacing(7.2),
              lg: theme.spacing(7.2),
              md: theme.spacing(7.2),
              sm: theme.spacing(6.2),
              xs: theme.spacing(6.2),
            },
            height: {
              xl: theme.spacing(7.2),
              lg: theme.spacing(7.2),
              md: theme.spacing(7.2),
              sm: theme.spacing(6.2),
              xs: theme.spacing(6.2),
            }
          }}
        >
          {typeof icon === 'string' ? <Iconify icon={icon} width={24} height={24} /> : icon}
        </StyledIcon>
        </Box>
        <Box>
          <Typography variant="subtitle2"
            color="#718EBF"
            fontFamily={"Inter"}
            fontSize={{
              xl: "12px",
              lg: "12px",
              md: "12px",
              sm: "10px",
              xs: "10px",
            }}
            textAlign={"left"}
            {...labelProps} >
            {title}
          </Typography>
          {noAmount ? null : <Typography 
            variant="h4" 
            fontFamily={"Inter"}
            textAlign={"left"}
            fontSize={{
              xl: "25px",
              lg: "25px",
              md: "25px",
              sm: "16px",
              xs: "16px",
            }}
            {...valueProps} >
            {value}
          </Typography>}
        
        </Box>
      </ButtonBase>
    </Card>
  );
}
