import Body from './body';
import PurchaseProvider from './provider';

const MonthlyPalmsPoint = () => {

  return (
    <PurchaseProvider>
      <Body />
    </PurchaseProvider>
  );
}

export default MonthlyPalmsPoint;
