import axiosClient from "@src/utils/axios-client";

export const getCoinTransactionBetStatsAPI = async (period: string) => {
    try {
        const result = await axiosClient.get("/coin-transactions/bet/chart", { params: { period }});
        return result.data;
    } catch (error: any) {
        throw error.response.data.error;
    }
};
