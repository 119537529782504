/* eslint-disable react-hooks/exhaustive-deps */
import { Helmet } from 'react-helmet-async';
import Body from './body';
import GameDetailsDialog from './game-details';
import Header from './header';
import GamesProvider from './provider';

export default function Games() {
  return (
    <GamesProvider>
      <>
        <Helmet>
          <title> Games </title>
        </Helmet>
        <Header />
        <Body />
        <GameDetailsDialog />
      </>
    </GamesProvider>
  );
}
