import GameDetailsDialog from '../games/game-details';
import GamesProvider from '../games/provider';
import TransactionProvider from './provider';
import Body from './body';
import { Helmet } from 'react-helmet-async';
import MostPlayedFilterSidebar from './filter';

const MostPlayed = () => {

  return (
    <TransactionProvider>
      <GamesProvider>
        <>
          <Helmet>
            <title> Most Played </title>
          </Helmet>
          <Body />
          <GameDetailsDialog />
          <MostPlayedFilterSidebar />
        </>
      </GamesProvider>
    </TransactionProvider>
  );
}

export default MostPlayed;
