import { useEffect, useState } from 'react';
// @mui
import { Menu, Button, MenuItem, Typography, Box } from '@mui/material';
// component
import Iconify from '@src/components/iconify/Iconify';
import useGameProviders from '@src/hooks/useGameProviders';
import { GameProviderType } from '@src/lib/types/game-providers';

// ----------------------------------------------------------------------

export default function ProviderName(props: any) {
  const { gameProviders, isLoading } = useGameProviders();

  const [open, setOpen] = useState(null);
  const [selected, setSelected] = useState<GameProviderType>();

  useEffect(() => {
    if (!isLoading && gameProviders) {
      setSelected(gameProviders[0]);
    }
  }, [gameProviders, isLoading])

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const onSelectionChange = (value: any) => {
    setOpen(null);
    setSelected(value);
    props.onChange(value.name);
  };

  return (
    <>
      <Button
        color="inherit"
        size={'large'}
        sx={{
          display: 'flex',
          flex: 1,
          height: 56,
          justifyContent: 'space-between',
          border: 0.7,
          borderColor: 'rgba(0,0,0,0.2)',
          color: 'text.secondary',
        }}
        onClick={handleOpen}
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
      >
        <Box>
          Provider Name:
          <Typography component="span" variant="subtitle2" sx={{ color: 'text.primary', marginLeft: 1, }}>
            {selected?.name}
          </Typography>
        </Box>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {gameProviders.map((option) => (
          <MenuItem
            key={option.name}
            selected={option.name === selected?.name}
            onClick={() => onSelectionChange(option)}
            sx={{ typography: 'body2' }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
