import UploadIcon from "@mui/icons-material/CloudUploadOutlined";
import ErrorIcon from '@mui/icons-material/Error';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import Config from "@src/utils/api/config";
import { tierName } from '@src/utils/transform-helper';
import _ from 'lodash';
import React from 'react';
import SelectItem from '../create-mail-dialog/select-item';
import { useInMailService } from '../hooks';
import Input from './input';
import InputDate from './input-date';
import { useUpdateMailContext } from './provider';
import {
  StyledStackRow,
  StyledTypography,
  styles
} from './styles.css';

function MailSettings() {
  const { products, games, uploadImage } = useInMailService({ getInMailDetails: true});
  const { state, error, enableEdit, onDispatch, onUpdateMailStatus } = useUpdateMailContext();

  const handleEveryone = (event: any) => {
    const isChecked = event.target.checked;
    if(isChecked){
      onDispatch("applicableTiers")([1,2,3,4]);
    }else{
      onDispatch("applicableTiers")([]);
    }
  }

  const onVisibleTo = (value: number) => {
    if(state.applicableTiers?.includes(value)){
      const filterArray = _.filter(state.applicableTiers, function(n) {
        return n !== value;
      });

      onDispatch("applicableTiers")(filterArray);
    }else{
      const newlyArray = state.applicableTiers || [];
      newlyArray.push(value);
      onDispatch("applicableTiers")(newlyArray);
    }
  }

  const onSchedule = (value: number) => {
    if(state.daysOfWeek?.includes(value)){
      const filterArray = _.filter(state.daysOfWeek, function(n) {
        return n !== value;
      });

      onDispatch("daysOfWeek")(filterArray);
    }else{
      const newlyArray = state.daysOfWeek || [];
      newlyArray.push(value);
      onDispatch("daysOfWeek")(newlyArray);
    }
  }

  const handleFileChange = (type: string) => (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", type);
      uploadImage.mutate(formData);
    }
  };

  React.useEffect(() => {
    if(uploadImage.data && uploadImage.isSuccess){
      onDispatch(uploadImage.data.type)(`${Config.APP_CDN_URL}${uploadImage.data.result.filePath}`);   
    }
  },[uploadImage.isSuccess]);

  return (
    <Box sx={{p: 5}}>
      <Stack direction="row">
        <Box sx={{flex: 1, p: 0}} >
          <StyledTypography sx={{fontSize: 20, fontWeight: 700, color: "black"}} >Settings</StyledTypography>
          <StyledStackRow sx={{gap: "0.4rem", alignItems: "center", justifyContent: "flex-start", mt: 4}}>
            <MonetizationOnIcon sx={{color: "#6A00AC"}} />
            <StyledTypography sx={{fontSize: 20, fontWeight: 700, color: "#6A00AC"}} >Coin Bonus</StyledTypography>
          </StyledStackRow>
          <StyledStackRow sx={{gap: "0rem", mt: 0}}>
            <Input sx={{width: "47%", mt: 0.8}} label="Silver Coins:"
              type='number'
              disabled={!enableEdit}
              error={error.silverAmount}
              value={state.silverAmount}
              onChange={(value) => {
                onDispatch("silverAmount")(value);
                onDispatch("gameId")(null);
                onDispatch("productId")(null);
                onDispatch("url")("");
                onDispatch("ctaType")("");
                onDispatch("actionButtonType")("COIN_BONUS");
              }}  />
            <Input sx={{width: "47%", mt: 0.8}} label="Bonus Gold Coins:" 
              type='number'
              disabled={!enableEdit}
              error={error.bonusGoldAmount}
              value={state.bonusGoldAmount}
              onChange={(value) => {
                onDispatch("bonusGoldAmount")(value)
                onDispatch("gameId")(null);
                onDispatch("productId")(null);
                onDispatch("url")("");
                onDispatch("ctaType")("");
                onDispatch("actionButtonType")("COIN_BONUS");
              }}/>
          </StyledStackRow>
          <StyledStackRow sx={{gap: "0.4rem", alignItems: "center", justifyContent: "flex-start", mt: 4}}>
            <IndeterminateCheckBoxIcon sx={{color: "#6A00AC"}} />
            <StyledTypography sx={{fontSize: 20, fontWeight: 700, color: "#6A00AC"}} >CTA Details</StyledTypography>
          </StyledStackRow>
          <SelectItem
            disabled={!enableEdit}
            options={games}
            label="Connect to Game:"
            placeholder="Select Game"
            value={state.gameId}
            getOptionLabel={(option: any) => `${option.gameName}`}
            onChange={(value) => {
              onDispatch("gameId")(value);
              onDispatch("productId")(null);
              onDispatch("url")("");
              onDispatch("ctaType")("GAME");
              onDispatch("silverAmount")("");
              onDispatch("bonusGoldAmount")("");
              onDispatch("actionButtonType")("CTA");
            }}  />
          <SelectItem
            options={products}
            disabled={!enableEdit}
            label="Connect to Store Package:"
            placeholder="Select Store Package"
            value={state.productId}
            getOptionLabel={(option: any) => `${option.description}`}
            onChange={(value) => {
              onDispatch("productId")(value);
              onDispatch("gameId")(null);
              onDispatch("url")("");
              onDispatch("ctaType")("PRODUCT");
              onDispatch("silverAmount")("");
              onDispatch("bonusGoldAmount")("");
              onDispatch("actionButtonType")("CTA");
            }}  />
          <Input label="URL:" 
            disabled={!enableEdit}
            error={error.url}
            value={state.url}
            onChange={(value) => {
              onDispatch("url")(value);
              onDispatch("productId")(null);
              onDispatch("gameId")(null);
              onDispatch("ctaType")("URL");
              onDispatch("silverAmount")("");
              onDispatch("bonusGoldAmount")("");
              onDispatch("actionButtonType")("CTA");
            }}/>
        </Box>
        <Box sx={{flex: 0.9, marginLeft: 10 }} >
          <Box sx={{ display: 'flex', flexDirection: "row", gap: "2rem", alignItems: "center", justifyContent: "center" }}>
            <Typography display="inline" variant="body1" sx={styles.t_status} >
              Status: 
              <Typography display="inline" variant="body1" fontWeight={"600"} 
                color={state?.isActive ? '#44B370' : "error.main"}>
                {state?.isActive ? "Active" : "Inactive"}
              </Typography>
            </Typography>
            <Button onClick={onUpdateMailStatus} 
              variant="contained"
              disabled={!enableEdit}
              sx={styles.btn_deactivation} >{state.isActive ? "DEACTIVATE" : "ACTIVATE"}</Button>
          </Box>
          <StyledStackRow sx={{gap: "0.4rem", alignItems: "center", justifyContent: "flex-start", mt: 4}}>
            <EventAvailableIcon sx={{color: "#6A00AC"}} />
            <StyledTypography sx={{fontSize: 20, fontWeight: 700, color: "#6A00AC"}} >Schedule</StyledTypography>
          </StyledStackRow>
          <StyledStackRow sx={{gap: "0rem", alignItems: "center", justifyContent: "flex-start", mt: 0}}>
            <InputDate label="Start Date" 
              sx={{width: "47%", mt: 0.8}}
              value={state.startDate}
              disabled={!enableEdit}
              onChange={onDispatch("startDate")}
              inputSx={{
                '& .MuiInputBase-root': {
                  borderRadius: "12px",
                  width: '197px',
                  height: '50px',
                },
              }} />
            <InputDate label="End Date" 
              value={state.endDate}
              disabled={!enableEdit}
              onChange={onDispatch("endDate")}
              sx={{width: "47%", mt: 0.8}}
              inputSx={{
                '& .MuiInputBase-root': {
                  borderRadius: "12px",
                  width: '197px',
                  height: '50px',
                },
              }} />
          </StyledStackRow>
          <Box sx={{ marginLeft: 0, paddingLeft: 0, display: 'flex', gap: "3rem" }}>
            <FormControl component="fieldset" variant="standard" disabled={!enableEdit}>
              <FormGroup>
              <FormControlLabel control={<Checkbox checked={state.daysOfWeek.includes(1)}  />} label="Mon" onChange={() => onSchedule(1)}/>
                <FormControlLabel control={<Checkbox checked={state.daysOfWeek.includes(2)}  />} label="Tue" onChange={() => onSchedule(2)}/>
                <FormControlLabel control={<Checkbox checked={state.daysOfWeek.includes(3)}  />} label="Wed" onChange={() => onSchedule(3)}/>
                <FormControlLabel control={<Checkbox checked={state.daysOfWeek.includes(4)}  />} label="Thu" onChange={() => onSchedule(4)}/>
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" variant="standard" disabled={!enableEdit}>
              <FormGroup>
              <FormControlLabel control={<Checkbox checked={state.daysOfWeek.includes(5)}  />} label="Fri" onChange={() => onSchedule(5)}/>
                <FormControlLabel control={<Checkbox checked={state.daysOfWeek.includes(6)}  />} label="Sat" onChange={() => onSchedule(6)}/>
                <FormControlLabel control={<Checkbox checked={state.daysOfWeek.includes(7)}  />} label="Sun" onChange={() => onSchedule(7)}/>
              </FormGroup>
            </FormControl>
          </Box>
          <Box sx={{ }}>
            <StyledStackRow sx={{gap: "0.4rem", alignItems: "center", justifyContent: "flex-start", mt: 2}}>
              <ErrorIcon sx={{color: "#6A00AC"}} />
              <StyledTypography sx={{fontSize: 20, fontWeight: 700, color: "#6A00AC"}} >Visible to:</StyledTypography>
            </StyledStackRow>
            <FormControl component="fieldset" variant="standard" disabled={!enableEdit} >
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={state.applicableTiers.length === 4} />} label="Everyone" onChange={handleEveryone} />
                <FormControlLabel control={<Checkbox checked={state.applicableTiers.includes(1)} />} label={tierName(1)} onChange={() => onVisibleTo(1)} />
                <FormControlLabel control={<Checkbox checked={state.applicableTiers.includes(2)} />} label={tierName(2)} onChange={() => onVisibleTo(2)}/>
                <FormControlLabel control={<Checkbox checked={state.applicableTiers.includes(3)} />} label={tierName(3)} onChange={() => onVisibleTo(3)}/>
                <FormControlLabel control={<Checkbox checked={state.applicableTiers.includes(4)} />} label={tierName(4)} onChange={() => onVisibleTo(4)}/>
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}

export default MailSettings;