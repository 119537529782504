import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { FormCheckBox, FormInput02 } from '@src/components/form-input';
import Iconify from '@src/components/iconify';
import { useToasterContext } from '@src/context/Toaster';
import { CodeGeneratorItemType } from '@src/lib/types/code-generator';
import { AlertType } from '@src/lib/types/toaster';
import Config from '@src/utils/api/config';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCodeGeneratorService } from '../hooks';
import { useCodeGeneratorContext } from '../provider';
import styles from "./styles.css";

function ItemDetails() {
  const [url, setUrl] = useState("");
  const { triggerOpen } = useToasterContext();
  const { state: {editDetails} } = useCodeGeneratorContext();
  const { onCreateCodeGenerator, onRefreshCodeGenerator } = useCodeGeneratorService();
  const { control, handleSubmit: handleSubmitDetails, 
    watch, getValues } = useForm<Partial<CodeGeneratorItemType>>();
  
  const onSubmitDetails = (data: Partial<CodeGeneratorItemType>) => {
    data.bonusAmount = data.bonusAmount === "custom" ? data.customAmount : data.bonusAmount;
    data.type = "REF";

    onCreateCodeGenerator.mutate(data, {
      onSuccess: (response) => {
        setUrl(Config.CODE_GENERATOR_URL + response.data.code);
        onRefreshCodeGenerator();
      },
      onError: (error: any) => {
        console.log("error", error);
        const message = error?.response?.data?.error?.message ?? error.message;

        triggerOpen({
          type: AlertType.error,
          message: message,
        });
      }
    });
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url);
    triggerOpen({
      message: "Copy success!",
    });
  }

  return (
    <Card sx={{ width: '100%', height: "100%", px: "30px", py: "15px", pb: 6, borderRadius: "25px" }}>
      <form
        onSubmit={handleSubmitDetails(onSubmitDetails)}
        style={{ flex: 1 }}
      >
        <Box display={"flex"} 
          flexDirection={"column"} 
          gap="1.7rem" 
          sx={{ width: "100%" }} >
          <Stack display={"flex"} direction={"row"} alignItems={"center"} justifyContent={"space-between"} pr={5} mt={2}>
            <Typography variant="h6" fontWeight={"700"}>
              Name Details
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: "row", gap: "2rem", alignItems: "center", justifyContent: "center" }}>
              <Typography display="inline" variant="body1" sx={styles.t_status} >
                Status: 
                <Typography display="inline" variant="body1" fontWeight={"600"} 
                  color={editDetails?.isActive ? '#44B370' : "error.main"}>
                  {editDetails?.isActive ? "Active" : "Inactive"}
                </Typography>
              </Typography>
              <Button 
              
                // onClick={onUpdateMailStatus} 
                variant="contained"
                // disabled={!enableEdit}
                sx={styles.btn_deactivation} >{editDetails.isActive ? "DEACTIVATE" : "ACTIVATE"}</Button>
            </Box>
          </Stack>
          <Stack direction={"row"} gap="4rem" justifyContent="center">
            <Stack gap="1rem" mt={2} flex={1}>
              <FormInput02
                name="name"
                disabled
                label="Promotion Name"
                control={control}
                rules={{ required: "Promotion name is required" }}
                labelProps={{
                  variant: "body1",
                  fontWeight: "700",
                }}
              />
              <FormInput02
                name="channelName"
                label="Channel Name"
                control={control}
                labelProps={{
                  variant: "body1",
                  fontWeight: "700",
                }}
              />
            </Stack>
            <Stack gap="1rem" mt={2} flex={1}>
              <FormInput02
                name="name"
                disabled
                placeholder='0'
                label="Number of Link Clicks"
                control={control}
                labelProps={{
                  variant: "body1",
                  fontWeight: "700",
                }}
              />
              <FormInput02
                name="userEngagement"
                label="User Engagement"
                placeholder='0'
                control={control}
                labelProps={{
                  variant: "body1",
                  fontWeight: "700",
                }}
              />
            </Stack>
            <Stack gap="1rem" width={270} mt={2} flex={0.8}>
              <Typography variant='body1' fontWeight={"700"} >
                Bonus Quality
              </Typography>
              <FormCheckBox label="1,000,000 Silver"
                disabled
                name="bonusAmount"
                control={control}
                defaultValue={1000000} />
              <Typography display="inline" variant='body1' fontSize={"14px"}>
                Start Date:{" "}
                <Typography display="inline" variant='body1' fontSize={"14px"} fontWeight={"700"}>
                  {editDetails.startAt}
                </Typography>
              </Typography>
              <Typography display="inline" variant='body1' fontSize={"14px"}>
                End Date:{" "}
                <Typography display="inline" variant='body1' fontSize={"14px"} fontWeight={"700"}>
                  {editDetails.endAt}
                </Typography>
              </Typography>
            </Stack>
          </Stack>
          <Stack direction={"row"} gap="2rem"
            px="17px"
            alignItems={"center"} 
            width="100%" height={79} 
            bgcolor={"#B8E0FC"} mt={2}
            borderRadius={"10px"}
            border="2px solid #72C5FF" >
            <Typography display="inline" variant='h6'>
              Code:
            </Typography>
            {/* <LoadingButton
              loading={onCreateCodeGenerator.isPending}
              type="submit"
              sx={{
                px: 5,
                borderRadius: "10px",
                color: "white",
                height: "50px",
                bgcolor: "#6A00AC"
              }}>
              GENERATE
            </LoadingButton> */}
            <Box 
              sx={{
                display: "flex", bgcolor: "white", width: "100%", 
                height: "50px", borderRadius: "10px", 
                pl: "16px", alignItems: "center",
                justifyContent: "space-between"
              }}>
              <Typography variant='body1' color="#B1B6BF">
                {url}
              </Typography>
              <Button
                disabled={!url}
                onClick={handleCopyLink}
                startIcon={<Iconify icon="fluent-mdl2:copy" width={17} height={17} />}
                sx={{
                  px: 8,
                  borderRadius: "10px",
                  color: "white",
                  height: "50px",
                  bgcolor: url ? "#0E39C1" : "#C3C3C3"
                }}>
                COPY LINK
              </Button>
            </Box>
          </Stack>
        </Box>
      </form>
    </Card>
  );
}

export default ItemDetails;