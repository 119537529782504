import axiosClient from "@src/utils/axios-client";

export const inviteTeamMemberAPI = async (params?: any) => {
  try {
    const result = await axiosClient.post(`/user/invite-portal-user`, {
      ...params,
    });
    return result;
  } catch (error: any) {
    throw error.response.data.error;
  }
};
