import { Button, IconButton, InputAdornment } from "@mui/material";
import Iconify from "@src/components/iconify";
import { UsersToolbarProps } from "@src/lib/types/users";
import { StyledRoot, StyledSearch, StyledWrap } from "@src/sections/@dashboard/styles.css";

import { useMostPlayedContext } from "./provider";
import { useMostPlayedService } from "./hooks";
import ButtonExport from "@src/components/buttons/button-export";

const Toolbar = ({ isLoading }: UsersToolbarProps) => {
    const { state, handleSearch, handleOpenFilterSidebar } = useMostPlayedContext();
    const { onRefreshMostPlayedList, onExportMostPlayedList } = useMostPlayedService({ shouldGetList: false });
    const { search } = state ?? {}

    return (
        <StyledRoot>
            <StyledSearch
                disabled={isLoading}
                value={search}
                onChange={(event) => handleSearch(event.target.value)}
                placeholder="Search game name..."
                startAdornment={
                    <InputAdornment position="start">
                        <Iconify
                            icon="eva:search-fill"
                            sx={{
                                color: 'text.disabled',
                                width: 20,
                                height: 20
                            }}
                        />
                    </InputAdornment>
                }
            />

            <StyledWrap>
                <Button
                    disableRipple
                    color="inherit"
                    endIcon={<Iconify icon="ic:round-filter-list" />}
                    onClick={handleOpenFilterSidebar}
                >
                    Filters&nbsp;
                </Button>
                <IconButton aria-label="delete" color="primary" onClick={onRefreshMostPlayedList}>
                    <Iconify icon={'mdi:refresh'} width={30} />
                </IconButton>
                <ButtonExport
                    sx={{ 
						marginLeft: 5, px: 5, 
						bgcolor: "purple.dark",
						"&:hover": {
							bgcolor: "purple.main"
						}
					}}
                    onClick={() => onExportMostPlayedList.mutate()}
                    loading={onExportMostPlayedList.isPending}
                />
            </StyledWrap>
        </StyledRoot>
    )
}

export default Toolbar