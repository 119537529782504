import { Box, Button, Card, Container } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import DataSection from './data-section'
import FilterSection from './filter-section'
import { useProgramsService } from './hooks'

type Props = {}

const Body = (props: Props) => {
    useProgramsService();

    const navigate = useNavigate()

    return (
        <Container maxWidth="xl">
            <Box
                display={"flex"}
                justifyContent={"flex-end"}
                mb={3}
            >
                <Button
                    size='large'
                    variant={"contained"}
                    onClick={() => navigate('/dashboard/programs/update')}
                    sx={{
                        backgroundColor: "#0E39C1",
                    }}
                >
                    Update
                </Button>
            </Box>
            <Card sx={{ px: 4, pt: 2, pb: 6, minHeight: 500 }}>
                <FilterSection />
                <DataSection />
            </Card>
        </Container>
    )
}

export default Body;