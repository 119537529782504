import { Box, Typography } from '@mui/material';

import Config from "@src/utils/api/config";
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
	Area,
	CartesianGrid,
	ComposedChart,
	Label,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';
import { usePlatformToolsServices } from './hooks';
import PositionedMenu from './menu';

const { isPalmsPlay } = Config;

const SubscriptionNumber = () => {
	const [period, setPeriod] = useState("MONTHLY");
	const { subscriptionChart, refetchSubscriptionChart } = usePlatformToolsServices({period});

	useEffect(() => {
		if(period){
			refetchSubscriptionChart();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[period]);

	if(isPalmsPlay) return null;

	return (
		<Box mt={5}>
			<Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
				Number of Subscriptions
			</Typography>
			<Box 
				p={"20px"} 
				borderRadius={"25px"} 
				display={"flex"}
				width={"100%"}
				height={350}
				flexDirection={"column"}
				bgcolor={"white"}>
				<PositionedMenu value={period} handleChange={(value: string) => setPeriod(value)} />
				<ResponsiveContainer width="100%" height="94%">
					<ComposedChart
						data={subscriptionChart?.data ?? []}
						margin={{
								top: 50,
								right: 10,
								bottom: 20,
								left: 10,
						}}>
						<CartesianGrid stroke="#f5f5f5" />
						<XAxis dataKey="label" height={25} />
						<YAxis width={30}>
							<Label
								content={<CustomYAxisLabel label="Total number of subscriptions" value={subscriptionChart?.totalCount} />}
								angle={0}
								dx={0}
								dy={-12}
								position="insideLeft"
							/>
						</YAxis>
						<Tooltip />
						<Area type="monotone" dataKey="value" fill="#8884d8" stroke="#8884d8" />
					</ComposedChart>
				</ResponsiveContainer>
				{period === "DAILY" && <Typography variant="h6" alignSelf={"center"} color="#718EBF" >
					Month of {moment().format("MMMM")}
				</Typography>}
			</Box>
		</Box>
	)
}

// Custom Label Component
export const CustomYAxisLabel = ({ viewBox, label, value }: any) => {
  const { x, y } = viewBox;

  return (
    <text x={x} y={y} dy={-27} dx={-10} fill="#000000">
      <tspan fontWeight="normal">{label}</tspan>
			<tspan> </tspan>
      <tspan fontWeight="bold">{value}</tspan>
    </text>
  );
};

export default SubscriptionNumber