import { createContext, useContext } from "react";

import { PartnerDropdownContextStateType, PartnerDropdownContextValueType, PartnerDropdownProviderProps } from "@src/lib/types/partner-provider";
import Config from "@src/utils/api/config";
import { useInputHelper } from "@src/utils/inputs-helper";

const initialState: PartnerDropdownContextStateType = {
    selectedPartnerId: Config.PARTNER_ID,
}

const PartnerDropdownContext = createContext<PartnerDropdownContextValueType>({} as PartnerDropdownContextValueType);

export const PartnerDropdownProvider = ({ children }: PartnerDropdownProviderProps) => {
    const { state, onDispatch } = useInputHelper(initialState);

    const handleSelectPartner = (value: PartnerDropdownContextStateType) => {
        onDispatch('selectedPartnerId')(value);
    }

    const value = {
        state,
        onDispatch,
        handleSelectPartner,
    };

    return (
        <PartnerDropdownContext.Provider value={value}>
            {children}
        </PartnerDropdownContext.Provider>
    )
}

export const usePartnerDropdownContext = () => {
    return useContext(PartnerDropdownContext);
};