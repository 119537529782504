import { Helmet } from 'react-helmet-async';
// @mui
// components
// sections
import GWCard from '@src/components/gw-card';

import { StyledBox } from './styles';
import ForgotSentSuccess from '@src/sections/auth/password/forgot-sent-success';

export default function ForgotPasswordSuccessPage() {
	return (
		<>
			<Helmet>
				<title> Forgot Password Success </title>
			</Helmet>

			<ForgotSentSuccess />
		</>
	);
}
