import { GameHistoryAPIParams } from "@src/lib/types/game-history";
import axiosClient from "@src/utils/axios-client";

export const allGameResultsAPI = async (params?: GameHistoryAPIParams) => {
  try {
    const requestParams = {
      page: 1,
      size: 10,
      sort: "CreatedDate,desc",
      ...params,
    };

    const result = await axiosClient.post(
      "/callback/report/getPlayResult",
      requestParams
    );

    return result.data.data || {};
  } catch (error: any) {
    throw error.response.data.error;
  }
};

export const exportGameHistoryAPI = async (params?: GameHistoryAPIParams) => {
  try {
    const res = await axiosClient.post(
      "/callback/report/getPlayResult/noPaging",
      params
    );
    return res;
  } catch (error) {
    throw error;
  }
};
