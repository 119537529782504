import { Helmet } from 'react-helmet-async';

import Body from './body';
import RedeemTransactionsFilterSidebar from './filter';
import Header from './header';
import RedeemProvider from './provider';
import UpdateRedeemStatus from './update-redeem-status';


export default function RedeemPage() {
  return (
    <RedeemProvider>
      <>
        <Helmet>
          <title> Redeem Transactions </title>
        </Helmet>
        <Header />
        <Body />
        <UpdateRedeemStatus />
        <RedeemTransactionsFilterSidebar />
      </>
    </RedeemProvider>
  );
}
