import { useMutation, useQuery } from "@tanstack/react-query";
import _reduce from "lodash/reduce";
import { useSearchParams } from "react-router-dom";

import { downloadExcel } from "@src/utils/download-helper";

import {
  GetAllPurchasesAPIParams,
  UsePurchasesServiceOperators,
} from "@src/lib/types/purchases";

import {
  totalPurchasesByMonths
} from "@src/api/purchases";
import { usePurchasesContext } from "./provider";

export const usePurchasesService = (): Readonly<UsePurchasesServiceOperators> => {
  const [params] = useSearchParams();
  const userId = params.get("u");

  const { state, handlePageChange } = usePurchasesContext();
  const {
    page,
    rowsPerPage,
    month,
    year,
  } = state || {};
  const newPage = (page || 0) + 1;

  const purchasesListQueryKey = [
    "total-purchases-by-months",
    {
      month,
      year,
      newPage,
      // order,
      // orderBy,
      rowsPerPage,
    },
  ];

  const { data, isLoading, isRefetching, refetch } = useQuery({
    queryKey: purchasesListQueryKey,

    queryFn: async () => {
      const reqParams: GetAllPurchasesAPIParams = {
        size: rowsPerPage,
        page: newPage,
      };

      // if (orderBy && order) reqParams.sort = `${orderBy},${order}`;
      if (month) reqParams.month = month;
      if (year) reqParams.year = year;

      const res = await totalPurchasesByMonths(reqParams);

      return res;
    },
  });


  const onExportPurchasesList = useMutation({
    mutationKey: ["export-purchases-months"],
    mutationFn: async () => {
      const reqParams: GetAllPurchasesAPIParams = {};

      reqParams.size = 10000;

      const res = await totalPurchasesByMonths(reqParams);

      const newJson = _reduce(
        res.content,
        (result: any, value: any, key) => {
          result.push({
            year: value.year,
            month: value.monthName,
            "purchase count": value.count,
            "total amount": value.totalAmount,
          });

          return result;
        },
        []
      );

      downloadExcel(newJson, "export-purchase");
    },
  });

  const onRefreshPurchases = () => {
    handlePageChange(null, 0);
    refetch();
  };

  return {
    data,
    purchases: data?.items || [],
    isLoading,
    isRefreshing: isRefetching,
    onRefreshPurchases,
    onExportPurchasesList,
  };
};
