import { Box } from "@mui/material"

type Props = {}

const SignupSuccess = (props: Props) => {
  return (
    <Box flex={1} sx={{
      height: '100%'
    }}>
      <img
        alt="signup success"
        src="/assets/images/signup/signup-success.png"
        style={{
          margin: '0 auto',
          pointerEvents: 'none'
        }}
      />
    </Box>
  )
}

export default SignupSuccess