import { useMutation, useQuery } from "@tanstack/react-query";

import { generateProgramsAPI } from "@src/api/programs";

import { providersWithShareListAPI, updatePercentShareAPI } from "@src/api/game-providers";
import { usePartnerDropdownContext } from "@src/context/PartnerDropdown";
import { UpdateGameProviderAPIRequestType } from "@src/lib/types/game-providers";
import { GenerateProgramsAPIParams, UseProgramsServiceOperators } from "@src/lib/types/programs";
import { useProgramsContext } from "./provider";

export const useProgramsService = (): Readonly<UseProgramsServiceOperators> => {
    const { state } = useProgramsContext();
    const { from_date, to_date, providerName = 'Evo' } = state || {};
    const { state: { selectedPartnerId } } = usePartnerDropdownContext();

    const generateProgramsQueryKey = [
        'generate-programs',
        {
            from_date,
            to_date,
            providerName,
            partnerId: selectedPartnerId,
        }
    ];

    const getProvidersWithRevShareQueryKey = [
        'get-providers-with-rev-share',
        {
            partnerId: selectedPartnerId
        }
    ];

    const { data, isLoading } = useQuery({
        queryKey: generateProgramsQueryKey,
        queryFn: async () => {
            const reqParams: GenerateProgramsAPIParams = {
                providerName,
            };

            if (from_date) reqParams.from_date = from_date;
            if (to_date) reqParams.to_date = to_date;

            const result = await generateProgramsAPI(reqParams);
            return result;
        },
    });

    const { data: providersWithShareData, isLoading: providersWithShareDataIsLoading, refetch: refetchProvidersWithShare } = useQuery({
        queryKey: getProvidersWithRevShareQueryKey,
        queryFn: async () => {
            const result = await providersWithShareListAPI({
                partnerId: selectedPartnerId
            });
            return result;
        },
    })

    const onUpdatePercentageShare = useMutation({
        mutationKey: ['update-percent-share', selectedPartnerId],
        mutationFn: async (items: any) => {
           return await updatePercentShareAPI({ ...items, partnerId: selectedPartnerId} as UpdateGameProviderAPIRequestType);
        },
        onSuccess: () => {
            refetchProvidersWithShare();
        }
    })

    return {
        data,
        result: data?.data || {},
        isLoading,
        onUpdatePercentageShare,
        providersWithShareData: providersWithShareData?.items || [],
        providersWithShareDataIsLoading
    }
};