import { useQuery } from "@tanstack/react-query";

import { licensesListAPI } from "@src/api/licenses";

import { useToasterContext } from "@src/context/Toaster";

import { GatAllLicensesAPIParams, UseManageLicensesServiceOperators } from "@src/lib/types/control-panel";

import { useManageLicensesContext } from "./provider";

export const useManageLicensesService = (): Readonly<UseManageLicensesServiceOperators> => {
    const { triggerOpen } = useToasterContext();

    const { state, handlePageChange } = useManageLicensesContext();
    const { size, page, order, orderBy, debouncedSearch } = state || {};
    const newPage = (page || 0) + 1;

    const licensesListQueryKey = [
        'manage-licenses',
        {
            size,
            newPage,
            order,
            orderBy,
            debouncedSearch,
        },
    ];

    const { data, isLoading, isRefetching, refetch } = useQuery({
        queryKey: licensesListQueryKey,
        queryFn: async () => {
            const reqParams: GatAllLicensesAPIParams = {
                size,
                page: newPage,
            };

            if (orderBy && order) reqParams.sort = `${orderBy},${order}`;

            if (debouncedSearch) reqParams.search = debouncedSearch;

            const usersRes = await licensesListAPI(reqParams);

            return usersRes;
        },
    });

    const onRefreshLicenses = () => {
        handlePageChange(null, 0);
        refetch();
    };

    return {
        data,
        licenses: data?.items || [],
        isLoading,
        isRefreshing: isRefetching,
        onRefreshLicenses,
    };
}