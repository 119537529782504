import { useQuery } from "@tanstack/react-query";

import { gameCategoriesListAPI } from "@src/api/game-categories";

import { GameCategoryType, UseGameCategoriesServiceOperators } from "@src/lib/types/game-categories";

const useGameCategories = (): Readonly<UseGameCategoriesServiceOperators> => {
    const { data, isLoading } = useQuery({
        queryKey: ["game-categories"],
        queryFn: async () => {
            const result = await gameCategoriesListAPI();
            return result;
        },
        select: (resData) => {
            if (resData.content.length) {
                resData.gameCategories = resData.content?.map((category: GameCategoryType) => {
                    return {
                        value: category.id,
                        label: category.gameTypeName,
                    };
                });
            }

            return resData;
        },
        staleTime: Infinity,
        gcTime: Infinity
    });

    return {
        data,
        gameCategories: data?.gameCategories || [],
        isLoading,
    };
};

export default useGameCategories;
