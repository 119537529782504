import React from 'react';
import { Box, Typography } from '@mui/material';

import { FormInput } from '@src/components/form-input';

import { useRedeemTransactionsContext } from '../provider';

const boxStyle = {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 1,
    marginTop: 2,
};

const PromotionItem = ({ control }: any) => {
    const { state } = useRedeemTransactionsContext();
    const { selected } = state || {};

    if (selected?.redeemTypeID !== 4) return null;

    return (
        <>
            <Typography
                sx={{
                    m: 0,
                    p: 2,
                    fontSize: 14,
                    fontWeight: "600"
                }}
            >
                Promotion Item
            </Typography>
            <Box sx={boxStyle}>
                <FormInput
                    name="productID"
                    control={control}
                    label="Product ID"
                    value={state?.selected?.productID}
                    disabled
                />
                <FormInput
                    name="productName"
                    control={control}
                    label="Product Name"
                    disabled
                />
            </Box>
        </>
    );
}

export default PromotionItem;