import { CodeGeneratorContextStateType, CodeGeneratorContextValueType, CodeGeneratorProviderProps } from '@src/lib/types/code-generator';
import { useInputHelper } from '@src/utils/inputs-helper';
import _debounce from 'lodash/debounce';
import moment from 'moment';
import { ChangeEvent, createContext, useCallback, useContext } from 'react';

const initialState: CodeGeneratorContextStateType = {
  page: 0,
  order: 'desc',
  orderBy: 'enrollmentDate',
  rowsPerPage: 10,
  editDetails: {},
  createOpen: false,
  name: "",
  search: "",
  filterSidebarOpen: false,
}

export const MissionContext = createContext<CodeGeneratorContextValueType>({} as CodeGeneratorContextValueType);

const CodeGeneratorProvider = ({ children }: CodeGeneratorProviderProps) => {
  const { state, onDispatch } = useInputHelper(initialState);

  const debouncedSearchFn = (value: string) => {
    onDispatch('page')(0);
    onDispatch('name')(value);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearch = useCallback(_debounce(debouncedSearchFn, 1000), []);

  const handleSearch = (value: string) => {
    onDispatch('search')(value);
    handleDebouncedSearch(value);
  }

  const handlePageChange = (event: unknown, newPage: number) => {
    onDispatch('page')(newPage);
  }

  const handleRequestSort = (property: string) => {
    const isAsc = state.orderBy === property && state.order === 'asc';
    onDispatch('order')(isAsc ? 'desc' : 'asc');
    onDispatch('orderBy')(property);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    onDispatch('rowsPerPage')(event.target.value);
  }

  const handleCodeGeneratorDetails = (item: any) => {
    onDispatch('editDetails')(item);
  }

  const handleCreateOpen = () => {
    onDispatch('createOpen')(!state.createOpen);
  }


  const handleOpenFilterSidebar = () => {
    onDispatch('filterSidebarOpen')(true);
  }

  const handleCloseFilterSidebar = () => {
    onDispatch('filterSidebarOpen')(false);
  }

  const handleApplyFilter = (formData: any) => {
    onDispatch('filterSidebarOpen')(false);
    onDispatch('fromDate')(formData.fromDate ? moment(formData.fromDate).format("YYYY-MM-DD") : null);
    onDispatch('toDate')(formData.toDate ? moment(formData.toDate).format("YYYY-MM-DD") : null);
}

const handleClearFilters = () => {
    onDispatch('fromdate')(null);
    onDispatch('todate')(null);
}

  const value = {
    state,
    onDispatch,
    handleSearch,
    handlePageChange,
    handleRequestSort,
    handleRowsPerPageChange,
    handleCodeGeneratorDetails,
    handleCreateOpen,
    handleApplyFilter,
    handleClearFilters,
    handleOpenFilterSidebar,
    handleCloseFilterSidebar
  };

  return (
    <MissionContext.Provider value={value}>
      {children}
    </MissionContext.Provider>
  );
};

export const useCodeGeneratorContext = () => {
  return useContext(MissionContext);
}

export default CodeGeneratorProvider;
