import { useQuery } from "@tanstack/react-query";

import {
  PurchaseChartAPI,
  SubscriptionsChartAPI,
  mostPopularDealAPI,
  mostPopularGoldShopItemAPI,
  mostPopularShopItemAPI,
  totalCompletedMissionsCountAPI,
  totalSubscriptionsCountAPI,
} from "@src/api/platform-tools";

import { UsePlatformToolsServiceOperators } from "@src/lib/types/platform-tools";

type Props = {
  period?: string;
}

export const usePlatformToolsServices =
  ({period}: Props): Readonly<UsePlatformToolsServiceOperators> => {

    const { data: totalMissionsCompleted } = useQuery({
      queryKey: ["total-number-missions-completed"],
      queryFn: async () => {
        const result = await totalCompletedMissionsCountAPI();
        return result;
      },
    });

    const { data: mostPopularDeal } = useQuery({
      queryKey: ["most-popular-deal"],
      queryFn: async () => {
        const result = await mostPopularDealAPI();
        return result;
      },
    });

    const { data: mostPopularGoldShopItem } = useQuery({
      queryKey: ["most-popular-gold-shop-item"],
      queryFn: async () => {
        const result = await mostPopularGoldShopItemAPI();
        return result;
      },
    });

    const { data: mostPopularShopItem } = useQuery({
      queryKey: ["most-popular-shop-item"],
      queryFn: async () => {
        const result = await mostPopularShopItemAPI();
        return result;
      },
    });

    const { data: totalSubscriptions } = useQuery({
      queryKey: ["total-number-subscriptions"],
      queryFn: async () => {
        const result = await totalSubscriptionsCountAPI();
        return result;
      },
    });

    const { data: subscriptionChart, refetch: refetchSubscriptionChart } = useQuery({
      queryKey: ["subscriptions-charts", period],
      queryFn: async () => {
        const result = await SubscriptionsChartAPI(period || "MONTHLY");

        return result;
      },
    });

    const { data: purchaseChart, refetch: refetchPurchaseChart } = useQuery({
      queryKey: ["purchase-charts", period],
      queryFn: async () => {
        const result = await PurchaseChartAPI(period || "MONTHLY");

        return result;
      },
    });

    return {
      totalMissionsCompleted,
      mostPopularDeal,
      mostPopularGoldShopItem,
      mostPopularShopItem,
      totalSubscriptions,
      subscriptionChart,
      refetchSubscriptionChart,
      purchaseChart,
      refetchPurchaseChart,
    };
  };
