import { CreateMailContextStateType, InMailAPIParams } from "@src/lib/types/in-mail-portal";
import axiosClient from "@src/utils/axios-client";

export const allMailResultsAPI = async (params?: InMailAPIParams) => {
  try {
    const requestParams = {
      page: 1,
      size: 10,
      "sort":"createdAt,desc",
      ...params,
    };

    const result = await axiosClient.post(
      "/mails",
      requestParams
    );

    return result.data.data || {};
  } catch (error: any) {
    throw error.response.data.error;
  }
};

export const createInMailAPI = async (params?: CreateMailContextStateType | any) => {
  try {
    const res = await axiosClient.post(
      "/mails/create",
      params
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const mailDetailsAPI = async (id?: number) => {
  try {
    const res = await axiosClient.get(`/mails/${id}`);
    return res?.data?.data ?? {};
  } catch (error) {
    throw error;
  }
};

export const updateMailDetailsAPI = async (params?: any) => {
  try {
    const res = await axiosClient.post(`/mails/${params.id}`, params);
    return res?.data?.data ?? {};
  } catch (error) {
    throw error;
  }
};

export const updateMailStatusAPI = async (id: number, params: any) => {
  try {
    const res = await axiosClient.post(
      `/mails/${id}/status`,
      params
    );
    return res?.data?.data ?? {};
  } catch (error) {
    throw error;
  }
};

export const uploadImageAPI = async (params?: any) => {
  try {
    delete axiosClient.defaults.headers['Content-Type'];
    const res = await axiosClient.post(
      "/files/upload",
      params
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export const gamesAPI = async () => {
  try {
    const res = await axiosClient.post("/game/list", { isActive: 1});
    return res?.data ?? [];
  } catch (error) {
    throw error;
  }
};

export const productsAPI = async (id?: number) => {
  try {
    const res = await axiosClient.get("/products/get");
    return res?.data ?? [];
  } catch (error) {
    throw error;
  }
};
