import { useMutation, useQuery } from "@tanstack/react-query";
import _reduce from "lodash/reduce";
import moment from "moment";

import {
  allGamesAPI,
  gameDetailsAPI,
  gamesListAPI,
  getMostPopularGames,
  getTotalGamesCount,
  updateGameAPI,
  uploadGameThumbnailAPI,
} from "@src/api/games";

import { downloadExcel } from "@src/utils/download-helper";
import { getProvider } from "@src/utils/filter-helper";

import {
  GameUploadImageAPIParams,
  GamesAPIParams,
  UpdateGameAPIPArams,
  UseGamesServiceOperators,
  UseGamesServiceParams,
} from "@src/lib/types/games";

import { useAuthService } from "@src/store/hooks";

import { useGamesContext } from "./provider";
import { useToasterContext } from "@src/context/Toaster";
import { AlertType } from "@src/lib/types/toaster";

export const useGamesService = (
  args?: UseGamesServiceParams
): Readonly<UseGamesServiceOperators> => {
  const {
    getMostPopularSilver = false,
    getMostPopularGold = false,
    getMostPopularGame = false,
    getTotalGames,
  } = args || {};

  const { currentUser } = useAuthService();
  const { triggerOpen } = useToasterContext();

  const { state, handlePageChange, handleCloseMoreDetails, handleCloseEditDetails } = useGamesContext();
  const { page, debouncedSearch, rowsPerPage, order, orderBy, provider, selectedGame } =
    state || {};
  const newPage = (page || 0) + 1;

  const gamesListQueryKey = [
    "games",
    {
      newPage,
      order,
      orderBy,
      rowsPerPage,
      debouncedSearch,
      provider,
    },
  ];

  const { data, refetch, isRefetching, isLoading } = useQuery({
    queryKey: gamesListQueryKey,
    queryFn: async () => {
      const reqParams: GamesAPIParams = {
        size: rowsPerPage,
        page: newPage,
      };

      if (typeof provider === "number") reqParams.providerID = provider;

      if (debouncedSearch) reqParams.search = debouncedSearch;

      if (orderBy && order) reqParams.sort = `${orderBy},${order}`;

      const gamesRes = await gamesListAPI(reqParams);

      return gamesRes;
    },
  });

  const { data: gameDetail, isLoading: isLoadingGameDetail } = useQuery({
    queryKey: ['game-details', selectedGame?.id],
    queryFn: async () => {
      if (selectedGame?.id) {
        const result = await gameDetailsAPI(selectedGame.id);
        return result;
      }
    },
    staleTime: 0,
    gcTime: 0,
    enabled: !!selectedGame?.id
  });

  const { data: totalGames } = useQuery({
    queryKey: ["total-games-count"],
    queryFn: async () => {
      const gamesRes = await getTotalGamesCount();
      return gamesRes;
    },
    enabled: !!currentUser?.id && getTotalGames,
  });

  const { data: mostPopularSilverGame } = useQuery({
    queryKey: ["most-popular-silver-game"],
    queryFn: async () => {
      const gamesRes = await getMostPopularGames({
        sort: "totalSilverBet,desc",
      });
      return gamesRes;
    },
    enabled: !!currentUser?.id && getMostPopularSilver,
  });

  const { data: mostPopularGoldGame } = useQuery({
    queryKey: ["most-popular-gold-game"],
    queryFn: async () => {
      const gamesRes = await getMostPopularGames({ sort: "totalGoldBet,desc" });
      return gamesRes;
    },
    enabled: !!currentUser?.id && getMostPopularGold,
  });

  const { data: mostPopularGame } = useQuery({
    queryKey: ["most-popular-game"],
    queryFn: async () => {
      const gamesRes = await getMostPopularGames({ sort: "totalPlayers,desc" });
      return gamesRes;
    },
    enabled: !!currentUser?.id && getMostPopularGame,
  });

  const onExportGamesList = useMutation({
    mutationKey: ["export-games-list"],
    mutationFn: async () => {
      const reqParams: GamesAPIParams = {};

      if (provider) reqParams.providerID = Number(provider);

      if (debouncedSearch) reqParams.search = debouncedSearch;

      if (orderBy && order) reqParams.sort = `${orderBy},${order}`;

      const res = await allGamesAPI(reqParams);

      const newJson = _reduce(
        res.data,
        (result: any, value: any, key) => {
          result.push({
            id: value.id,
            "Game Name": value.gameName,
            Provider: getProvider(value.gameTypeID),
            Code: value.code,
            "Tier ID": value.tierID,
            Status: value.isActive === 1 ? "Active" : "Inactive",
            "New Game": value.isNew === 1 ? "Yes" : "No",
            "For Features": value.isFeatured === 1 ? "Yes" : "No",
            "Created Date": moment(value.enrollmentDate).format(
              "MM-DD-YYYY hh:mm A"
            ),
            "Last Update": moment(value.lastModifiedDate).format(
              "MM-DD-YYYY hh:mm A"
            ),
          });

          return result;
        },
        []
      );

      downloadExcel(newJson, "export-games");
    },
  });

  const onRefreshPurchases = () => {
    handlePageChange(null, 0);
    refetch();
  };

  const onUploadGameThumbnail = useMutation({
    mutationKey: ['game-thumbnail-upload'],
    mutationFn: async (args: GameUploadImageAPIParams) => {
      const result = await uploadGameThumbnailAPI(args);
      return result.body?.data || {};
    },
    onSuccess: () => {
      triggerOpen({
        title: "Upload Thumbnail",
        message: "Successfully uploaded a game thumbnail",
      });
    },
    onError: () => {
      triggerOpen({
        type: AlertType.error,
        title: "Upload Thumbnail",
        message: "Failed to upload a game thumbnail",
      });
    },
  });

  const onUpdateGameDetails = useMutation({
    mutationKey: ['update-game-details'],
    mutationFn: async (args?: Partial<UpdateGameAPIPArams>) => {
      const result = await updateGameAPI(args);
      return result;
    },
    onSuccess: () => {
      refetch();
      handleCloseMoreDetails();
      handleCloseEditDetails();

      triggerOpen({
        title: "Update Game Details",
        message: "Successfully updated the game details",
      });
    },
    onError: () => {
      triggerOpen({
        type: AlertType.error,
        title: "Update Game Details",
        message: "Failed to update the game details",
      });
    },
  });

  return {
    data,
    games: data?.items || [],
    gameDetail,
    totalGames,
    isLoading,
    isRefreshing: isRefetching,
    isLoadingGameDetail,
    mostPopularGoldGame,
    mostPopularSilverGame,
    mostPopularGame,
    onRefreshPurchases,
    onExportGamesList,
    onUploadGameThumbnail,
    onUpdateGameDetails,
  };
};
