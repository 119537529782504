import React, { useEffect, useState } from 'react'
import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';

import { redeemTypeName } from '@src/utils/filter-helper';
import { REDEEM_TRANSACTIONS_STATUS_OPTIONS } from '@src/utils/mockdata/menu-options';

import { Dialog } from '@src/components/dialog'
import { Select } from '@src/components/select';
import { FormInput } from '@src/components/form-input';

import { useRedeemTransactionsContext } from '../provider';
import PromotionItem from './promotion-item';
import GiftCardItem from './gift-card-item';
import BankDetails from './bank-details';
import PaypalDetails from './paypal-details';
import ProofOfIDs from './proof-of-ids';
import { useRedeemTransactionsService } from '../hooks';

const boxStyle = {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 2,
    gap: 1,
};

const UpdateRedeemStatus = () => {
    const { control, reset, handleSubmit } = useForm();

    const { onUpdateRedeemTransaction } = useRedeemTransactionsService();
    const { state, handleCloseUpdateRedeemStatus } = useRedeemTransactionsContext();
    const { updateRedeemStatusOpen, selected } = state || {};

    const onSubmit = (formData: any) => {
        if (selected) {
            const params = {
                ...selected,
                goldAmount: selected.goldAmount,
                bankFirstName: selected.bank_FirstName,
                bankLastName: selected.bank_LastName,
                bankName: selected.bank_Name,
                bankSwiftCode: selected.bank_SwiftCode,
                bankAccountNumber: selected.bank_AccountNumber,
                bankIbanIbcCode: selected.bank_IBAN_IBC_Code,
                "paypalEmail": selected.paypal_Email,
                "paypalFirstName": selected.paypal_FirstName,
                "paypalLastName": selected.paypal_UserName,
                redeemStatus: {
                    id: formData.redeemStatusID,
                    name: REDEEM_TRANSACTIONS_STATUS_OPTIONS.find((item) => item.value === formData.redeemStatusID)?.label,
                },
                redeemType: {
                    id: selected.redeemTypeID,
                    name: selected.redeemTypeID ? redeemTypeName(selected.redeemTypeID) : ''
                },
            };
            onUpdateRedeemTransaction.mutate(params as any, {
                onSuccess: () => handleCloseUpdateRedeemStatus()
            });
        }
    }

    useEffect(() => {
        if (selected) {
            reset({
                goldAmount: selected.goldAmount,
                redeemTypeID: redeemTypeName(selected.redeemTypeID),
                redeemStatusID: selected.redeemStatusID,
                giftCardID: selected.giftCardID,
                giftCardName: selected.giftCardName,
                productID: selected.productID,
                productName: selected.productName,
                bank_FirstName: selected.bank_FirstName,
                bank_LastName: selected.bank_LastName,
                bank_Name: selected.bank_Name,
                bank_AccountNumber: selected.bank_AccountNumber,
                bank_SwiftCode: selected.bank_SwiftCode,
                bank_IBAN_IBC_Code: selected.bank_IBAN_IBC_Code,
                paypal_FirstName: selected.paypal_FirstName,
                paypal_LastName: selected.paypal_LastName,
                paypal_Email: selected.paypal_Email,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected]);

    const dialogContent = selected ? (
        <form id="update-redeem-form" onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ width: '100%' }}>
                <Box sx={boxStyle}>
                    <LoadingButton
                        // loading={loading2} 
                        sx={{ width: '100%' }}
                    // onClick={onSelectUserID}
                    >
                        USER ID: {selected.userID}
                    </LoadingButton>
                    <FormInput
                        label="Gold Amount"
                        name="goldAmount"
                        control={control}
                        disabled
                    />
                </Box>
                <Box sx={boxStyle}>
                    <FormInput
                        name="redeemTypeID"
                        label="Redeem Type"
                        control={control}
                        disabled
                    />
                    <Select
                        name='redeemStatusID'
                        control={control}
                        label="Status"
                        labelId="country-select"
                        options={REDEEM_TRANSACTIONS_STATUS_OPTIONS}
                    />
                </Box>

                <PromotionItem control={control} />
                <GiftCardItem control={control} />
                <BankDetails control={control} />
                <PaypalDetails control={control} />
                <ProofOfIDs />
            </Box>
        </form>
    ) : null;

    return (
        <Dialog
            isOpen={updateRedeemStatusOpen}
            onClose={handleCloseUpdateRedeemStatus}
            dialogTitle="Update Redeem Status"
            dialogContent={dialogContent}
            dialogActions={(
                <>
                    <Button
                        type="submit"
                        variant='contained'
                        form="update-redeem-form"
                    // loading={loading}
                    // onClick={handleUpdate}
                    >
                        Update
                    </Button>
                    <Button
                        // disabled={loading}
                        autoFocus
                        sx={{ color: 'text.secondary' }}
                        onClick={handleCloseUpdateRedeemStatus}
                    >
                        Cancel
                    </Button>
                </>
            )}
        />
    )
}

export default UpdateRedeemStatus