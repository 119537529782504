// DUCKS pattern
import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { RootState } from '../index';
import * as Types from '../types';

export interface StateValue {
  loading: boolean;
  exporting: boolean;
  data: any;
  error: any;
  exportError: any;
}

const initialState: StateValue = {
  loading: false,
  exporting: false,
  data: {} as any,
  error: {} as any,
  exportError: {} as any,
} as StateValue;

// Slice
export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    transactionRequest: (state, action) => {
      state.loading = true;
      if (action.payload.filter) {
        state.data = {} as any;
      }
    },
    transactionSuccess: (state, action) => {
      let items = action.payload.items;
      if (action.payload.isFetchMore) {
        items = _.union(state.data?.items ?? [], action.payload.items);
      }

      state.data = { ...action.payload, items };
      state.loading = false;
    },
    transactionFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetError: () => {
      return initialState;
    },

    transactionExportRequest: (state, action) => {
      state.exporting = true;
    },
    transactionExportSuccess: (state, action) => {
      state.exporting = false;
    },
    transactionExportFailure: (state, action) => {
      state.exportError = action.payload;
      state.exporting = false;
    },
  },
});

// Actions
export const transactionActions = {
  transactionRequest: createAction(
    `${transactionSlice.name}/transactionRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  transactionSuccess: transactionSlice.actions.transactionSuccess,
  transactionFailure: transactionSlice.actions.transactionFailure,
  resetError: transactionSlice.actions.resetError,

  transactionExportRequest: createAction(
    `${transactionSlice.name}/transactionExportRequest`,
    (params: any) => ({
      payload: params,
    })
  ),
  transactionExportSuccess: transactionSlice.actions.transactionExportSuccess,
  transactionExportFailure: transactionSlice.actions.transactionExportFailure,
};

// Selectors
export const selectedTransactionLoading = (state: RootState) => state.transaction.loading;
export const selectedTransactionExporting = (state: RootState) => state.transaction.exporting;
export const selectedTransactionData = (state: RootState) => state.transaction.data;
export const selectedTransactionFailed = (state: RootState) => state.transaction.error;
export const selectedTransactionItems = createSelector(
  (state: RootState) => state.transaction.data,
  state => state.items || []
);
export const selectedTransactionTotal = createSelector(
  (state: RootState) => state.transaction.data,
  state => state.totalRecords || 0
);

// Reducer
export default transactionSlice.reducer;
