
const styles = {
  reset_stack: {
    pl: "10%",
    pr: "10%",
    bgcolor: "white",
    margin: '0 auto',
    boxShadow: 1,
    borderRadius: "16px",
    pt: {
      xs: '30px',
      sm: '30px',
      md: '50px',
      lg: '56px',
      xl: '56px',
    },
    pb: {
      xs: '30px',
      sm: '30px',
      md: '50px',
      lg: '56px',
      xl: '56px',
    },
    width: {
      xs: '98%',
      sm: '98%',
      md: '60%',
      xl: '55%',
    },
  },
  t_reset_password: { 
    fontSize: {
      xs: 20,
      sm: 20,
      md: 20,
      lg: 24,
      xl: 24,
    }, 
    fontFamily: "Inter",
    color: 'black' 
  },
  t_description: {  
    fontSize: {
      xs: 13,
      sm: 13,
      md: 13,
      lg: 14,
      xl: 14,
    }, 
    fontFamily: "Inter",
    fontWeight: "700",
    color: '#64748B' 
  },
}

export default styles;