import { LoadingButton } from '@mui/lab';
import { Box, Button, FormGroup, Stack, Typography } from '@mui/material';
import { FormCheckBox, FormDatePicker, FormInput, FormInput02 } from '@src/components/form-input';
import Iconify from '@src/components/iconify';
import { useToasterContext } from '@src/context/Toaster';
import { CodeGeneratorItemType } from '@src/lib/types/code-generator';
import { AlertType } from '@src/lib/types/toaster';
import Config from '@src/utils/api/config';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useCodeGeneratorService } from '../hooks';


function ReferralCode() {
  const { triggerOpen } = useToasterContext();
  const [url, setUrl] = useState("");
  const { onCreateCodeGenerator, onRefreshCodeGenerator } = useCodeGeneratorService();
  const { control, handleSubmit: handleSubmitDetails, 
    watch, getValues } = useForm<Partial<CodeGeneratorItemType>>();
  
  const onSubmitDetails = (data: Partial<CodeGeneratorItemType>) => {
    data.bonusAmount = data.bonusAmount === "custom" ? data.customAmount : data.bonusAmount;
    data.type = "REF";

    onCreateCodeGenerator.mutate(data, {
      onSuccess: (response) => {
        setUrl(Config.CODE_GENERATOR_URL + response.data.code);
        onRefreshCodeGenerator();
      },
      onError: (error: any) => {
        console.log("error", error);
        const message = error?.response?.data?.error?.message ?? error.message;

        triggerOpen({
          type: AlertType.error,
          message: message,
        });
      }
    });
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url);
    triggerOpen({
      message: "Copy success!",
    });
  }

  return (
    <>
    <form
      onSubmit={handleSubmitDetails(onSubmitDetails)}
      style={{ flex: 1 }}
    >
      <Box display={"flex"} 
        flexDirection={"column"} 
        gap="1.7rem" 
        sx={{ width: "100%" }}>
        <Stack direction={"row"} gap="4rem" justifyContent="center">
          <Stack gap="1rem" mt={2} flex={1}>
            <FormInput02
              name="name"
              label="Promotion Name"
              control={control}
              rules={{ required: "Promotion name is required" }}
              labelProps={{
                variant: "body1",
                fontWeight: "700",
              }}
            />
            <FormInput02
              name="code"
              label="Enter Promo Code"
              control={control}
              placeholder='(max 10 letters or numbers)'
              rules={{ required: "Promo code is required" }}
              labelProps={{
                variant: "body1",
                fontWeight: "700",
              }}
            />
            <Box>
              <FormInput02
                name="channelName"
                label="Channel Name"
                control={control}
                rules={{ required: "Channel name is required" }}
                labelProps={{
                  variant: "body1",
                  fontWeight: "700",
                }}
              />
              <Typography sx={{
                mt: "5px",
                fontSize: 12,
                fontStyle: "italic",
                color: "#B1B6BF"
              }} >Enter Channel where you will use this link</Typography>
            </Box>
            
          </Stack>
          <Stack gap="1rem" width={270} mt={2} flex={0.8}>
            <Typography variant='body1' fontWeight={"700"} >
              Bonus Quality
            </Typography>
            <FormGroup sx={{flexDirection: "row"}}>
              <FormCheckBox label="1,000,000 Silver"
                name="bonusAmount"
                control={control}
                defaultValue={1000000} />
              <FormCheckBox label="2,000,000 Silver"
                name="bonusAmount"
                control={control}
                defaultValue={2000000} />
              <FormCheckBox label="3,000,000 Silver"
                name="bonusAmount"
                control={control}
                defaultValue={3000000} />
              <FormCheckBox label="Custom Amount"
                name="bonusAmount"
                control={control}
                defaultValue="custom" />
              <FormInput
                name="customAmount"
                control={control}
                type="number"
                disabled={watch("bonusAmount") !== "custom"}
                rules={{ 
                  validate: () => {
                    if(!getValues("bonusAmount")){
                      return "Bonus quality is required"
                    }
                  }
                 }}
                sx={{ml: 4, width: "55%"}}
              />
            </FormGroup>
          </Stack>
          <Stack gap="1rem" mt={2}>
            <Typography variant='body1' fontWeight={"700"}>
              Start and Expiry Date
            </Typography>
            <FormDatePicker
              name="startAt"
              label="Start Date:"
              control={control}
              rules={{ required: "Start Date is required" }}
              labelProps={{
                variant: "body1",
                fontWeight: "500",
                sx: {
                  fontSize: 14
                }
              }}
              sx={{
                width: '50%',
                backgroundColor: '#ffffff',
                color: '#111111',
                '& .MuiInputBase-root': {
                  height: {
                    xs: '40px',
                    sm: '40px',
                    md: '45px',
                    lg: '45px',
                    xl: '50px',
                  },
                },
              }}
            />
            <FormDatePicker
              name="endAt"
              label="End Date:"
              control={control}
              rules={{ required: "End Date is required" }}
              labelProps={{
                variant: "body1",
                fontWeight: "500",
                sx: {
                  fontSize: 14
                }
              }}
              sx={{
                width: '50%',
                height: {
                  xs: '40px',
                  sm: '40px',
                  md: '45px',
                  lg: '45px',
                  xl: '50px',
                },
                backgroundColor: '#ffffff',
                color: '#111111',
                '& .MuiInputBase-root': {
                  height: {
                    xs: '40px',
                    sm: '40px',
                    md: '45px',
                    lg: '45px',
                    xl: '50px',
                  },
                },
              }}
            />
          </Stack>
        </Stack>
        <Stack direction={"row"} gap="2rem"
          px="17px"
          alignItems={"center"} 
          width="100%" height={79} 
          bgcolor={"#B8E0FC"} mt={2}
          borderRadius={"10px"}
          border="2px solid #72C5FF" >
          <LoadingButton
            loading={onCreateCodeGenerator.isPending}
            type="submit"
            sx={{
              px: 5,
              borderRadius: "10px",
              color: "white",
              height: "50px",
              bgcolor: "#6A00AC"
            }}>
            GENERATE
          </LoadingButton>
          <Box 
            sx={{
              display: "flex", bgcolor: "white", width: "100%", 
              height: "50px", borderRadius: "10px", 
              pl: "26px", alignItems: "center",
              justifyContent: "space-between"
            }}>
            <Typography variant='body1' color="#B1B6BF">
              {url}
            </Typography>
            <Button
              disabled={!url}
              onClick={handleCopyLink}
              startIcon={<Iconify icon="fluent-mdl2:copy" width={17} height={17} />}
              sx={{
                px: 8,
                borderRadius: "10px",
                color: "white",
                height: "50px",
                bgcolor: url ? "#0E39C1" : "#C3C3C3"
              }}>
              COPY LINK
            </Button>
          </Box>
        </Stack>
      </Box>
    </form>
    </>
  );
}

export default ReferralCode;