import { useMutation, useQuery } from "@tanstack/react-query";

import queryClient from "@src/query-client";

import { inviteTeamMemberAPI } from "@src/api/control-panel";
import { allUsersAPI, updateUserDetailsAPI } from "@src/api/users";
import {
  GetAllUsersParams,
  UserObjectType,
  UsersAPIREquest,
  UserTypeID,
} from "@src/lib/types/users";

import { useToasterContext } from "@src/context/Toaster";
import {
  InviteUserFormValues,
  UseManageTeamServiceOperators,
} from "@src/lib/types/control-panel";
import { AlertType } from "@src/lib/types/toaster";

import { useAuthService } from "@src/store/hooks";

import { useManageTeamContext } from "./provider";

export const useManageTeamService =
  (): Readonly<UseManageTeamServiceOperators> => {
    const { currentUser } = useAuthService();
    const { partnerID } = currentUser || {};

    const { triggerOpen } = useToasterContext();
    const {
      state,
      handleToggleInviteModal,
      handlePageChange,
      handleToggleUpdateUserModal,
    } = useManageTeamContext();
    const { size, page, order, orderBy } = state || {};
    const newPage = (page || 0) + 1;

    // allow all access roles except Player
    const allowedUserAccess: Record<UserTypeID, number[]> = {
      2: [2, 3, 4, 5], // developer
      3: [3, 4, 5],    // owner
      4: [3, 4, 5],    // admin
      5: [5],          // general
    };

    const userAccess = allowedUserAccess[currentUser?.userTypeID as UserTypeID];

    const usersListQueryKey = [
      "manage-team-users",
      {
        size,
        newPage,
        order,
        orderBy,
        userTypeIDs: userAccess,
      },
    ];

    const { data, isLoading, isRefetching, refetch } = useQuery({
      queryKey: usersListQueryKey,
      queryFn: async () => {
        const reqParams: GetAllUsersParams = {
          size,
          page: newPage,
          userTypeIDs: userAccess,
        };

        if (orderBy && order) reqParams.sort = `${orderBy},${order}`;

        const usersRes = await allUsersAPI(reqParams);

        return usersRes;
      },
    });

    const onInviteUser = useMutation({
      mutationFn: async (formData: InviteUserFormValues) => {
        try {
          await inviteTeamMemberAPI({
            partnerId: partnerID.id,
            ...formData,
          });
          handleToggleInviteModal(false);
          triggerOpen({
            title: "Invite user",
            message: "Successfully sent invitation to user",
          });
        } catch (error: any) {
          triggerOpen({
            type: AlertType.error,
            title: "Invite user",
            message: error.message
          });
        }
      },
    });

    const _updateUserItemFromList = (newUserData: UserObjectType) => {
      queryClient.setQueryData(
        usersListQueryKey,
        (usersRes: UsersAPIREquest) => {
          const newItems = usersRes?.items?.map((user: UserObjectType) => {
            if (user.id === newUserData.id) {
              return {
                ...user,
                ...newUserData,
              };
            }

            return user;
          });

          return {
            ...usersRes,
            items: newItems,
          };
        }
      );
    };

    const onUpdateUserDetails = useMutation({
      mutationKey: ["manage-team-update-user-details"],
      mutationFn: updateUserDetailsAPI,
      onSuccess: ({ data }) => {
        _updateUserItemFromList(data.data);

        triggerOpen({
          message: "Successfully updated user details.",
        });

        handleToggleUpdateUserModal();
      },
    });

    const onRefreshUsers = () => {
      handlePageChange(null, 0);
      refetch();
    };

    return {
      data,
      users: data?.items,
      isLoading,
      isRefreshing: isRefetching,
      onInviteUser,
      onRefreshUsers,
      onUpdateUserDetails,
    };
  };
