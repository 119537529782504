import styled from '@emotion/styled';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField
} from '@mui/material';
import React from 'react';
import { useInMailService } from './hooks';
import { useInMailContext } from './provider';

const BodyHeader = () => {
  const { data } = useInMailService();
  const { state, handleSearch, handleCreateMailDialog } = useInMailContext();

  return (
    <StyledWrapRow>
      <StyledWrapRight>
        <Box sx={{ fontWeight: "700", color: "rgba(69, 69, 69, 1)" }}>Mail List</Box>
        <StyledCountMails>
          {data?.totalRecords ?? 0} in mails
        </StyledCountMails>
      </StyledWrapRight>
      <StyledWrapRight>
        <StyledSearchStack>
          <StyledSearchInput
            id="input-search"
            value={state.search}
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <StyledButtonFilter startIcon={<AddIcon />}>
            Filters
          </StyledButtonFilter>
        </StyledSearchStack>
        <StyledButtonCreate onClick={handleCreateMailDialog} startIcon={<AddIcon />}>
          Create New Mail
        </StyledButtonCreate>
      </StyledWrapRight>
    </StyledWrapRow>
  );
}

const StyledSearchStack = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: "row",
  height: 40,
  borderRadius: 8,
  border: '1px solid rgba(33, 33, 33, 0.2)',
}));

const StyledSearchInput = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flexDirection: "row",
  height: 40,
  borderRadius: 0,
  borderRight: '1px solid rgba(33, 33, 33, 0.2)',
}));

const StyledButtonFilter = styled(Button)(({ theme }) => ({
  height: 40,
  width: 114,
  display: 'flex',
  color: "#848484",
  backgroundColor: "transparent",
}));


const StyledWrapRight = styled(Box)(() => ({
  height: 96,
  display: 'flex',
  flexDirection: "row",
  alignItems: "center",
  marginLeft: 0,
  paddingLeft: 0,
  gap: '1rem'
}));

const StyledWrapRow = styled(Box)(() => ({
  height: 96,
  width: "100%",
  display: 'flex',
  alignItems: "center",
  justifyContent: 'space-between',
}));

const StyledButtonCreate = styled(Button)(({ theme }: any) => ({
  height: 40,
  width: 192,
  marginRight: 0,
  display: 'flex',
  color: "white",
  borderRadius: 8,
  backgroundColor: theme.palette.purple.dark,
}));

const StyledCountMails = styled(Box)(() => ({
  width: 95,
  height: 31,
  fontSize: 14,
  display: 'flex',
  fontWeight: 500,
  borderRadius: 48,
  alignItems: "center",
  justifyContent: 'center',
  color: "rgba(33, 33, 33, 1)",
  backgroundColor: "rgba(33, 33, 33, 0.1)",
}));

export default React.memo(BodyHeader);
