import React, { useMemo } from 'react'
import sections from '@src/lib/data/portal-guide.json';
import { scrollToSection } from '@src/utils/scroll-to-section';
import Config from '@src/utils/api/config';

const { isPalmsPlay } = Config;

const TableOfContents = () => {
    const handleScroll = (event: any, id: string) => {
        event.preventDefault();
        const offset = 170; // Adjust this value to your header height
        scrollToSection(id, offset);
    };

    const newSections = useMemo(() => {
        if (!isPalmsPlay) return sections;

        return sections.filter((section) => {
            const { title } = section;
            return title !== "How to Check Redeem Transactions and Update Redeem Status";
        });
    }, []);

    return (
        <div>
            <h2>Table of Contents</h2>
            <ul>
                {newSections.map((section, index) => (
                    <li key={index}>
                        <a href="/" onClick={(event) => handleScroll(event, `section-${index}`)}>
                            {section.title}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default TableOfContents